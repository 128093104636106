import React from "react";
import "./banner.scss";
const Banner = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="banner_inner" style={{ background: props.image }}>
          <div className="text">
            <h4>{props.title}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
