import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";

import { Form, Input, Button, InputNumber, notification } from "antd";
import Banner from "../../components/Banner/Banner";

import { useDispatch, useSelector } from "react-redux";
import { fetchContactData } from "../../actions/apiActon";
import Preloader from "../../components/Preloader/Preloader";

import axios from "axios";

import "antd/dist/antd.css";
import "./contact.scss";

const Contact = () => {
  const myForm = useRef();

  const onFinish = (values) => {
    axios
      .post("https://app.aamakoachar.com/api/contact-form", values)
      .then((response) => {
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            notification["error"]({
              description: error,
            });
          });
        } else {
          notification["success"]({
            description: response.data.message,
          });

          myForm.current.resetFields();
        }
      });
  };

  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { contactData, loading } = mainData;

  const dataArr = contactData && contactData.data;

  useEffect(() => {
    dispatch(fetchContactData());
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {dataArr && (
            <>
              <Banner
                image={`url(https://app.aamakoachar.com/storage/${dataArr.contact.banner_image})`}
                title={dataArr.contact.banner_title}
              />

              <section className="contact_info">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="co_wrap">
                        <h5>Visit Us</h5>
                        <article
                          dangerouslySetInnerHTML={{
                            __html: dataArr.contact.address,
                          }}
                        ></article>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="co_wrap">
                        <h5>Get in touch</h5>
                        <a
                          href={`mailto:${dataArr.contact.email}`}
                          className="mail-underline"
                        >
                          {dataArr.contact.email}
                        </a>
                        <a href="#"> {dataArr.contact.telephone}</a>
                        <a href="#"> {dataArr.contact.mobile}</a>
                      </div>
                    </div>
                  </div>

                  <div className="contact_map">
                    <a href={dataArr.contact.direction} target="_blank">
                      Get Direction <i className="fas fa-location-arrow"></i>
                    </a>
                  </div>
                </div>
              </section>

              <section className="form-fill">
                <div className="intro_head">
                  <h6>LEAVE A MESSAGE</h6>
                  <h4>
                    Need additional information? Have a question or comment?
                  </h4>
                </div>
                <div className="container">
                  <Form name="contact_form" ref={myForm} onFinish={onFinish}>
                    <Row>
                      <Col span={24}>
                        <div className="form_card">
                          <Form.Item
                            name={["formData", "name"]}
                            label="Full Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Full Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="form_card">
                          <Form.Item
                            name={["formData", "contact_number"]}
                            label="Phone Number"
                            rules={[
                              {
                                required: true,
                                message: "Please input your contact Number!",
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="form_card">
                          <Form.Item
                            name={["formData", "email"]}
                            label="Email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Please input your email address!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="form_card">
                          <Form.Item
                            name={["formData", "message"]}
                            label="Your Message"
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <div className="submit_btn">
                      {/* <button onClick={submitRequest}>Submit Request</button> */}
                      <Form.Item>
                        <div className="line-btn">
                          <Button type="primary" htmlType="submit">
                            Submit request
                          </Button>
                        </div>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Contact;
