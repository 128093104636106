import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData, fetchProductData } from "../../actions/apiActon";

import "./header.scss";

const Header = () => {
  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { homeData } = mainData;

  useEffect(() => {
    dispatch(fetchHomeData());
  }, []);

  const dataArr = homeData && homeData.data;

  const [navbar, setNavbar] = useState(false);
  const [isOpen, setIsopen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 280) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    let button = document.querySelector(".nav_click");
    let navBox = document.querySelector(".navs");
    let links = navBox.querySelectorAll("a");

    links.forEach((link) => {
      link.addEventListener("click", () => {
        setIsopen(false);
      });
    });

    button.addEventListener("click", () => {
      setIsopen(!isOpen);
    });
  });

  function handleCollapse() {
    const navCol = document.querySelector(".navbar-collapse");
    navCol.classList.remove("show");
  }

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <header className={navbar ? "active" : ""}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="logo">
                <Link className="nav-link" to="/">
                  {dataArr ? (
                    <>
                      <img
                        src={`https://app.aamakoachar.com/storage/${dataArr.content.logo}`}
                        alt=""
                      />
                    </>
                  ) : (
                    <img src="./images/logo.svg" alt="" />
                  )}
                </Link>
              </div>
            </div>

            <div className="col-md-9">
              <div className={isOpen ? "nav_click active" : "nav_click"}>
                <span></span>
                <span></span>
              </div>

              <div className={isOpen ? "navs show" : "navs"}>
                <ul>
                  <li>
                    <Link className="nav-link" to="/">
                      {" "}
                      Home{" "}
                    </Link>
                  </li>

                  <li>
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>

                  <li>
                    <Link className="nav-link" to="/our-products">
                      Our Products
                    </Link>
                  </li>

                  <li>
                    <Link className="nav-link" to="/contacts">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
