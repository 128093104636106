import React from "react";
import "./style.scss";

const Feature = (props) => {
  return (
    <>
      <section className="sperate">
        <div className="container">
          <div className="row">
            {props.data.map((val, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div className="sep-sing">
                    <img
                      src={`https://app.aamakoachar.com/storage/${val.image}`}
                      alt={val.title}
                    />
                    <h5>{val.title}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
