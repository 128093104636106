import React from "react";

import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import ScrollTop from "./components/ScrollTop";
import ProductDetail from "./pages/Products/ProductDetail";
import Contact from "./pages/Contact/Contact";

const Layout = () => {
  return (
    <>
      <ScrollTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/our-products" element={<Products />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/contacts" element={<Contact />} />
      </Routes>

      <Footer />
    </>
  );
};

export default Layout;
