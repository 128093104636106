import React, { useEffect } from "react";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./style.scss";

gsap.registerPlugin(ScrollTrigger);

const Difference = () => {
  useEffect(() => {
    gsap.to(".lead img", {
      scrollTrigger: {
        trigger: ".difference",
        start: "top center-=20%",
        scrub: 3,
      },
      y: -325,
      duration: 8,
    });
    gsap.to(".jar img", {
      scrollTrigger: {
        trigger: ".difference",
        start: "top center-=20%",
        scrub: 3,
      },
      y: 210,
      duration: 5,
    });

    let fastMovers = document.querySelectorAll(".fast");
    let mediumMovers = document.querySelectorAll(".medium");
    let slowMovers = document.querySelectorAll(".slow");
    let rotateFast = document.querySelectorAll(".rotateFast");
    let rotateFastReverse = document.querySelectorAll(".rotateFastReverse");
    let rotateMedium = document.querySelectorAll(".rotateMedium");

    rotateFastReverse.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        rotation: -90,
      });
    });

    rotateFast.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        rotation: 90,
      });
    });

    rotateMedium.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        rotation: -90,
      });
    });

    fastMovers.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        y: -450,
      });
    });

    mediumMovers.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        y: -350,
      });
    });

    slowMovers.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: ".difference",
          start: "top center-=35%",
          scrub: 3,
        },

        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        y: -250,
      });
    });
  });

  return (
    <>
      <section className="difference">
        <div className="wrapper">
          <div className="lead">
            <img src="./images/anim/lead.png" alt="" />
          </div>
          <div className="jar">
            {/* <img src="./images/anim/jarback.png" className="back" alt="" /> */}
            <img src="./images/anim/jar.png" className="front" alt="" />
          </div>

          <div className="ingredients">
            <img src="./images/anim/1.png" className="slow" alt="" />
            <img src="./images/anim/2.png" className="slow rotateFast" alt="" />
            <img src="./images/anim/3.png" className="medium" alt="" />
            <img src="./images/anim/4.png" className="slow" alt="" />
            <img src="./images/anim/5.png" className="medium" alt="" />
            <img src="./images/anim/6.png" className="medium" alt="" />
            <img
              src="./images/anim/7.png"
              className="fast rotateFastReverse"
              alt=""
            />
            <img
              src="./images/anim/8.png"
              className="slow rotateMedium"
              alt=""
            />
            <img src="./images/anim/9.png" className="medium" alt="" />
            <img src="./images/anim/10.png" className="fast" alt="" />
            <img src="./images/anim/11.png" className="medium" alt="" />
            <img
              src="./images/anim/12.png"
              className="fast rotateFast"
              alt=""
            />
            <img src="./images/anim/13.png" className="slow" alt="" />
            <img src="./images/anim/14.png" className="fast" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Difference;
