export function fetchDataRequest(bool) {
  return {
    type: "FETCH_DATA_REQUEST",
    bool,
  };
}

export function fetchHomeDataSuccess(data) {
  return {
    type: "FETCH_HOME_DATA_SUCCESS",
    data,
  };
}

export function fetchDataError(error) {
  return {
    type: "FETCH_DATA_ERROR",
    payload: { error },
  };
}

export function fetchAboutDataRequest() {
  return {
    type: "FETCH_ABOUT_DATA_REQUEST",
  };
}

export function fetchAboutDataSuccess(data) {
  return {
    type: "FETCH_ABOUT_DATA_SUCCESS",
    data,
  };
}

export function fetchAboutDataError(error) {
  return {
    type: "FETCH_ABOUT_DATA_ERROR",
    payload: { error },
  };
}

export function fetchProductDataRequest() {
  return {
    type: "FETCH_PRODUCT_DATA_REQUEST",
  };
}

export function fetchProductDataSuccess(data) {
  return {
    type: "FETCH_PRODUCT_DATA_SUCCESS",
    data,
  };
}

export function fetchProductDataError(error) {
  return {
    type: "FETCH_PRODUCT_DATA_ERROR",
    payload: { error },
  };
}

export function fetchContactDataSuccess(data) {
  return {
    type: "FETCH_CONTACT_DATA_SUCCESS",
    data,
  };
}
