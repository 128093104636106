import React from "react";
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button";

import { useLocation } from "react-router-dom";

import "./product.scss";

const ProductDetail = () => {
  const location = useLocation();
  const { detail } = location.state;

  return (
    <>
      <Banner image={`url('./images/about.jpg')`} title={detail.title} />

      <section className="product_detail">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="img">
                <img
                  src={`https://app.aamakoachar.com/storage/${detail.image}`}
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-7">
              <div className="text">
                <h4>{detail.title}</h4>
                <h6>{detail.subtitle}</h6>

                <article
                  dangerouslySetInnerHTML={{ __html: detail.description }}
                ></article>
              </div>
            </div>
          </div>

          <div className="see_all">
            <Button buttonText="See all products" buttonLink="/our-products" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetail;
