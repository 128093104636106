const initialState = {
  loading: false,
  homeData: [],
  aboutData: [],
  productData: [],
  contactData: [],
  error: null,
};

function reducers(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DATA_REQUEST":
      return {
        ...state,
        loading: action.bool,
      };

    case "FETCH_HOME_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        homeData: action.data,
      };
    case "FETCH_ABOUT_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        aboutData: action.data,
      };

    case "FETCH_PRODUCT_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        productData: action.data,
      };

    case "FETCH_CONTACT_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        contactData: action.data,
      };
    case "FETCH_DATA_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducers;
