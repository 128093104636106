import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./Layout";
import "./main.scss";

import { Provider } from "react-redux";
import dataStore from "./store/store";

const App = () => {
  return (
    <>
      <Provider store={dataStore()}>
      <Router>
        <Layout />
      </Router>
      </Provider>
    </>
  );
};

export default App;
