import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ProductData from "../../store/ProductData";
import "./product.scss";

import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../actions/apiActon";
import Preloader from "../../components/Preloader/Preloader";

const Products = () => {
  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { productData, loading } = mainData;

  const productArr = productData.data && productData.data.product;

  useEffect(() => {
    dispatch(fetchProductData());
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {productArr && (
            <>
              <Banner
                image={`url(https://app.aamakoachar.com/storage/${productArr.basic.basic_banner_image})`}
                title={productArr.basic.basic_banner_title}
              />

              <section className="about_intro">
                <div className="container">
                  <div className="intro_head">
                    <h6>{productArr.basic.basic_title}</h6>
                    <h4>{productArr.basic.basic_subtitle}</h4>
                  </div>
                </div>
              </section>

              <div className="product_list">
                <div className="container">
                  <div className="row">
                    {productArr.items.map((val, index) => {
                      return (
                        <div className="col-md-4" key={index}>
                          <div className="sp-wrap">
                            <Link to="/product-detail" state={{ detail: val }}>
                              <img
                                src={`https://app.aamakoachar.com/storage/${val.image}`}
                                alt=""
                              />
                              <h6>{val.title}</h6>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Products;
