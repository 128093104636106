import React from "react";
import "./style.scss";

// Import Swiper styles
import "swiper/css";

const Available = (props) => {
  return (
    <section className="av_section">
      <div className="container">
        <div className="sec-head">
          <h5>We are available at</h5>
        </div>

        <div className="slide_wrap">
          {props.data.map((val, index) => {
            return (
              <div className="slides" key={index}>
                <img
                  src={`https://app.aamakoachar.com/storage/${val.image}`}
                  alt={val.title}
                />

                <p>{val.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Available;
