import React from "react";
import "./styles.scss";

const Preloader = () => {
  return (
    <>
      <section className="preloader">
        <div className="img">
          <img src="./images/logo.svg" alt="" />
        </div>
      </section>
    </>
  );
};

export default Preloader;
