import React, { useEffect } from "react";
import "./footer.scss";

import { useDispatch, useSelector } from "react-redux";
import { fetchContactData } from "../../actions/apiActon";

import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { contactData, loading } = mainData;

  const dataArr = contactData && contactData.data;

  useEffect(() => {
    dispatch(fetchContactData());
  }, []);
  return (
    <>
      {dataArr && (
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="footer_logo">
                  <h4>Have a question or comment?</h4>

                  <div className="reach_out">
                    <p>Reach Out</p>

                    <a
                      href={`mailto:${dataArr.contact.email}`}
                      className="mail-underline"
                    >
                      <MailOutlined />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="footer_half">
                  <div className="half">
                    <div className="connect">
                      <h5>Contact</h5>
                      <a href="#">
                        <article
                          dangerouslySetInnerHTML={{
                            __html: dataArr.contact.address,
                          }}
                        ></article>
                      </a>
                      <a
                        href={`mailto:${dataArr.contact.email}`}
                        className="mail-underline"
                      >
                        {dataArr.contact.email}
                      </a>
                      <a href="#"> {dataArr.contact.telephone}</a>
                    </div>
                  </div>

                  <div className="half">
                    <div className="connect">
                      <h5>Pages</h5>

                      <Link to="/about-us">About Us</Link>
                      <Link to="/our-products">Products</Link>
                      <Link to="/contacts">Contact</Link>
                    </div>
                  </div>
                </div>

                <div className="socail">
                  <a href={dataArr.contact.facebook} target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>

                  <a href={dataArr.contact.instagram} target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>

                  <a href={dataArr.contact.youtube} target="_blank">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="footer_bottom">
              <div className="row">
                <div className="col-md-6">
                  <p>© 2021 Aamakoachar. All rights reserved</p>
                </div>

                <div className="col-md-6">
                  <p className="align-right">
                    Powered by{" "}
                    <a href="https://www.hueshine.com/" target="_blank">
                      Hue Shine
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
