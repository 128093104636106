import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";

import "./home.scss";
import "/node_modules/react-modal-video/scss/modal-video.scss";

import Difference from "../../components/HomeAnim/Difference";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import ModalVideo from "react-modal-video";

// Import Swiper styles
import "swiper/css";
import Feature from "../../components/Featured/Feature";
import Available from "../../components/Available/Available";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData, fetchProductData } from "../../actions/apiActon";
import Preloader from "../../components/Preloader/Preloader";
import { Link } from "react-router-dom";

const Home = () => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { homeData, productData, loading } = mainData;

  console.log(homeData);

  useEffect(() => {
    dispatch(fetchHomeData());
    dispatch(fetchProductData());
  }, []);

  const dataArr = homeData && homeData.data;
  const productArr = productData.data && productData.data.product.items;

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {dataArr && (
            <>
              <section className="banner">
                <div
                  className="overlay banner-overlay"
                  style={{ background: "url(/images/grid.png)" }}
                ></div>
                <video autoPlay muted loop id="myVideo">
                  <source
                    src={`https://app.aamakoachar.com/storage/${dataArr.bannervideo}`}
                    type="video/mp4"
                  />
                </video>

                <div className="banner-text">
                  <h1>{dataArr.content.title}</h1>
                  <p>{dataArr.content.subtitle}</p>
                </div>
              </section>

              <section className="intro">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <div className="intro-text">
                        <h4>{dataArr.content.taste_title}</h4>

                        <article
                          dangerouslySetInnerHTML={{
                            __html: dataArr.content.taste_description,
                          }}
                        ></article>

                        <Button buttonText="Our Story" buttonLink="/about-us" />
                      </div>
                    </div>

                    <div className="col-md-6 offset-md-1">
                      <div className="intro-img">
                        <img
                          src={`https://app.aamakoachar.com/storage/${dataArr.content.taste_image}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="video">
                <div className="container-fluid">
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId={dataArr.content.video_link}
                    onClose={() => setOpen(false)}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="vd-bg"
                        style={{
                          background: `url(https://app.aamakoachar.com/storage/${dataArr.content.about_image})`,
                        }}
                      >
                        <div className="overlay vd-overlay"></div>

                        <div className="play-btn" onClick={() => setOpen(true)}>
                          <img src="/images/play.svg" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="vd-text">
                        <h4>{dataArr.content.about_title} </h4>

                        <article
                          dangerouslySetInnerHTML={{
                            __html: dataArr.content.about_description,
                          }}
                        ></article>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Difference />

              {productArr && (
                <>
                  <section className="products">
                    <div className="sec-head">
                      <h5>Our Products</h5>
                    </div>

                    <div className="prd-slide">
                      <div className="container-fluid">
                        <Swiper
                          className="productSlider"
                          modules={[Autoplay]}
                          autoplay={{
                            delay: 3,
                          }}
                          speed={4500}
                          loop={true}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                          }}
                        >
                          {productArr.map((val, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="sp-wrap">
                                  <Link
                                    to="/product-detail"
                                    state={{ detail: val }}
                                  >
                                    <img
                                      src={`https://app.aamakoachar.com/storage/${val.image}`}
                                      alt=""
                                    />
                                    <h6>{val.title}</h6>
                                  </Link>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>

                        <div className="prdts-more">
                          <Button
                            buttonText="Discover More"
                            buttonLink="/our-products"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}

              <Feature data={dataArr.about} />

              <section className="testimonials">
                <div className="container">
                  <div className="sec-head">
                    <h5>WORDS FROM OUR CUSTOMERS</h5>
                  </div>

                  <Swiper
                    className="clientSlides"
                    modules={[Autoplay]}
                    autoplay={{
                      delay: 5000,
                    }}
                    speed={1500}
                    loop={true}
                    centeredSlides={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {dataArr.testimonial.map((val, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="cl-wrap">
                            <img
                              src={`https://app.aamakoachar.com/storage/${val.image}`}
                              alt=""
                            />

                            <div className="desc">
                              <article
                                dangerouslySetInnerHTML={{
                                  __html: val.description,
                                }}
                              ></article>

                              <h5>{val.name}</h5>
                              <span>{val.designation}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </section>

              <Available data={dataArr.shop} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
