import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import Feature from "../../components/Featured/Feature";

import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData, fetchAboutData } from "../../actions/apiActon";
import Preloader from "../../components/Preloader/Preloader";

import "./about.scss";

const About = () => {
  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.data);

  const { aboutData, loading } = mainData;

  const aboutArr = aboutData.data && aboutData.data.about;

  useEffect(() => {
    dispatch(fetchAboutData());
    dispatch(fetchHomeData());
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {aboutArr && (
            <>
              <Banner
                image={`url(https://app.aamakoachar.com/storage/${aboutArr.basic.basic_banner_image})`}
                title={aboutArr.basic.basic_banner_title}
              />

              <section className="about_intro">
                <div className="container">
                  <div className="intro_head">
                    <h6>{aboutArr.basic.basic_title}</h6>
                    <h4>{aboutArr.basic.basic_subtitle}</h4>

                    <article
                      dangerouslySetInnerHTML={{
                        __html: aboutArr.basic.basic_description1,
                      }}
                    ></article>
                  </div>
                </div>
              </section>

              <section className="about_half">
                <div className="container">
                  <div className="row align-items-end">
                    <div className="col-md-6">
                      <div className="text">
                        <h4>{aboutArr.basic.basic_purpose}</h4>
                        <article
                          dangerouslySetInnerHTML={{
                            __html: aboutArr.basic.basic_purpose_description,
                          }}
                        ></article>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="image">
                        <img
                          src={`https://app.aamakoachar.com/storage/${aboutArr.basic.basic_purpose_image}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <Feature /> */}
              <Feature data={aboutArr.items} />

              <section className="about_half ">
                <div className="container">
                  <div className="row align-items-end">
                    <div className="col-md-6">
                      <div className="image">
                        <img
                          src={`https://app.aamakoachar.com/storage/${aboutArr.basic.basic_footer_image}`}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text padding_left">
                        <h4>{aboutArr.basic.basic_footer_title}</h4>
                        <article
                          dangerouslySetInnerHTML={{
                            __html: aboutArr.basic.basic_footer_description,
                          }}
                        ></article>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default About;
