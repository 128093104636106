import {
  fetchDataRequest,
  fetchHomeDataSuccess,
  fetchAboutDataSuccess,
  fetchProductDataSuccess,
  fetchContactDataSuccess,
  fetchDataError,
} from "./action";
import axios from "axios";

export function fetchHomeData() {
  return (dispatch) => {
    dispatch(fetchDataRequest(true));

    axios
      .get("https://app.aamakoachar.com/api/homepage")
      .then((response) => {
        dispatch(fetchHomeDataSuccess(response.data));
        dispatch(fetchDataRequest(false));
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
        dispatch(fetchDataRequest(false));
      });
  };
}

export function fetchAboutData() {
  return (dispatch) => {
    dispatch(fetchDataRequest(true));

    axios
      .get("https://app.aamakoachar.com/api/about")

      .then((response) => {
        dispatch(fetchAboutDataSuccess(response.data));
        dispatch(fetchDataRequest(false));
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
        dispatch(fetchDataRequest(false));
      });
  };
}

export function fetchProductData() {
  return (dispatch) => {
    dispatch(fetchDataRequest(true));

    axios
      .get("https://app.aamakoachar.com/api/product")

      .then((response) => {
        dispatch(fetchProductDataSuccess(response.data));
        dispatch(fetchDataRequest(false));
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
        dispatch(fetchDataRequest(false));
      });
  };
}

export function fetchContactData() {
  return (dispatch) => {
    dispatch(fetchDataRequest(true));

    axios
      .get("https://app.aamakoachar.com/api/contact")

      .then((response) => {
        dispatch(fetchContactDataSuccess(response.data));
        dispatch(fetchDataRequest(false));
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
        dispatch(fetchDataRequest(false));
      });
  };
}
